// export const API_URL = "https://sugar.digis.cloud/strapi";
export const API_URL = "https://sugarapp.it/strapi";
// export const API_URL = "http://localhost:1337";
// export const SOCKET_URL = "https://sugar.digis.cloud";
export const SOCKET_URL = "https://sugarapp.it";
// export const SOCKET_URL = "http://localhost:3000";
// export const SITE_URL = "https://sugar.digis.cloud";
export const SITE_URL = "https://sugarapp.it";
// export const SITE_URL = "http://localhost:3000";
export const TOKEN_KEY = "strapi-jwt-token";
